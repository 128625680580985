import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';

function Head({
  description = '',
  lang = 'en',
  meta = [],
  title = '',
  keywords = '',
  linkTags = [],
  children = null,
  scriptTags = [],
  noIndex = false,
  noFollow = false,
}) {
  let noIndexFollow = [];
  if (process.env.GATSBY_ROBOTS_INDEX === 'true') {
    if (noIndex) noIndexFollow.push('noindex');
    else noIndexFollow.push('index');

    if (noFollow) noIndexFollow.push('nofollow');
    else noIndexFollow.push('follow');
  } else {
    noIndexFollow = ['noindex', 'nofollow'];
  }

  return (
    <Helmet
      htmlAttributes={{ lang }}
      title={title}
      meta={[
        {
          name: 'robots',
          content: noIndexFollow?.length && noIndexFollow.join(','),
        },
        {
          name: 'description',
          content: description,
        },
        {
          name: 'keywords',
          content: keywords,
        },
        ...meta,
      ]}
    >
      {process.env.GATSBY_COOKIEPRO_SITE_ID && [
        <script
          key="otSDKStub"
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          data-domain-script={process.env.GATSBY_COOKIEPRO_SITE_ID}
        />,
      ]}
      {linkTags.map(linkTag => linkTag)}
      {children}
      {scriptTags.map(scriptTag => scriptTag)}
    </Helmet>
  );
}

Head.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  keywords: PropTypes.string,
  linkTags: PropTypes.arrayOf(PropTypes.shape({})),
  children: PropTypes.node,
  scriptTags: PropTypes.arrayOf(PropTypes.shape({})),
  noIndex: PropTypes.bool,
  noFollow: PropTypes.bool,
};

export default Head;
