import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled, { useTheme } from 'styled-components';
import ChevronRight from '@rsc/ui-components/svgs/chevron-right.svg';
import breakpoints from '@rsc/ui-components/breakpoints';

const Wrapper = styled.nav`
  display: none;
  height: 90px;

  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    justify-content: center;
    flex: 1;
  }
`;

const NavDesktopMenuItem = styled.div`
  color: white;
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: ${({ theme }) => theme.navigation.NavDesktopMenuItemFontSize};
  font-weight: inherit;
  margin-bottom: -1rem;
  padding: 0.5rem 1rem;
  margin-top: -1rem;
  text-transform: ${({ theme }) => theme.navigation.mainItemTextTransform};
  &:first-child {
    padding-left: 5rem;
    margin-left: -5rem;
  }
  svg {
    transition: all 0.5s ease;
    fill: ${({ theme }) => theme.colors.NavLinkColor};
  }
  a {
    transition: all 0.5s ease;
    &:hover {
      text-decoration: underline;

      svg {
        fill: ${({ theme }) => theme.colors.NavLinkColor};
      }
    }
  }
`;

const NavDesktopLink = styled(Link)`
  display: flex;
  align-self: stretch;
  align-items: center;
  color: inherit;
  font-weight: ${({ theme }) => theme.navigation.fontWeight};
  padding: 0.25rem;
  text-decoration: none;
`;

const DropdownSubMenu = styled.ul`
  position: absolute;
  background: ${({ theme }) => theme.colors.background};
  top: -0.25rem;
  left: 14rem;
  border: solid 1px inherit;
  width: 15rem;
  margin: 0;
  padding: 0;
  display: ${props => (props.smallMenuActive ? 'block' : 'none')};
  box-shadow: rgba(0, 0, 0, 0.02) 0 2.8px 2.2px,
    rgba(0, 0, 0, 0.027) 0 6.7px 5.3px, rgba(0, 0, 0, 0.035) 0 12.5px 10px,
    rgba(0, 0, 0, 0.043) 0 22.3px 17.9px, rgba(0, 0, 0, 0.05) 0 41.8px 33.4px,
    rgba(0, 0, 0, 0.07) 0 100px 80px;
`;

const DropdownMenu = styled.div`
  border: solid 1px inherit;
  display: ${props => (props.dropdownActive ? 'flex' : 'none')};
  background: ${({ theme }) => theme.colors.background};
  position: absolute;
  top: 122px;
  z-index: 2;
  box-shadow: rgba(0, 0, 0, 0.02) 0 2.8px 2.2px,
    rgba(0, 0, 0, 0.027) 0 6.7px 5.3px, rgba(0, 0, 0, 0.035) 0 12.5px 10px,
    rgba(0, 0, 0, 0.043) 0 22.3px 17.9px, rgba(0, 0, 0, 0.05) 0 41.8px 33.4px,
    rgba(0, 0, 0, 0.07) 0 100px 80px;
  color: inherit; // make prop?
  width: 15rem;

  ul {
    list-style: none;
    padding: 0.25rem 0;
    margin: 0;
    width: 100%;

    li {
      margin: 0;
      padding: 0;
      position: relative;
      z-index: 2;

      &:hover,
      &:focus {
        ${DropdownSubMenu} {
          display: block;
        }
      }

      a {
        font-size: 1rem;
        display: block;
        text-decoration: none;
        font-weight: inherit;
        padding: 0.5rem 1rem;
        transition: all 0.5s ease;
        color: inherit;

        &:hover {
          color: inherit;
          text-decoration: underline;
        }
      }
    }
  }

  @media (max-width: calc(${breakpoints.lg} - 1)) {
    display: none;
  }
`;

const NavDesktopButton = styled.button`
  display: flex;
  align-items: center;
  background: inherit;
  align-self: stretch;
  font-weight: ${({ theme }) => theme.navigation.fontWeight};
  border: 0;
  color: inherit;
  padding: 0.5rem;
  text-decoration: none;
  text-transform: ${({ theme }) => theme.navigation.mainItemTextTransform};
  cursor: pointer;
  pointer-events: auto;
  svg {
    margin-left: ${({ theme }) => theme.navigation.downArrowSpacing};
  }

  a {
    color: inherit;
    font-weight: inherit;
  }
`;

function NavDesktop({
  navLinks,
  megaMenuActive,
  setKeyboardNavActive,
  setMegaMenuActive,
  keyboardNavActive,
  flags,
}) {
  const theme = useTheme();
  const shopNavItem = React.useRef(null);
  const [dropdownActive, setDropdownActive] = useState(null);
  const [smallMenuActive, setSmallMenuActive] = useState(false);

  useEffect(() => {
    if (megaMenuActive < 0 && keyboardNavActive) {
      shopNavItem.current.focus();
    }
  }, [megaMenuActive]);

  return (
    <Wrapper>
      {navLinks.map((navLink, megaMenuIndex) => (
        <NavDesktopMenuItem
          key={navLink.name}
          onMouseEnter={() => {
            if (navLink.type === 'megaMenu') setMegaMenuActive(megaMenuIndex);
            if (navLink.type === 'dropdown') setDropdownActive(navLink.name);
          }}
          onMouseLeave={() => {
            if (navLink.type === 'megaMenu') setMegaMenuActive(null);
            if (navLink.type === 'dropdown') setDropdownActive(null);
          }}
          onClick={() => {
            if (navLink.type === 'megaMenu') {
              setMegaMenuActive(
                megaMenuActive === megaMenuIndex ? null : megaMenuIndex
              );
            }
            if (navLink.type === 'dropdown') {
              if (dropdownActive) {
                setDropdownActive(null);
              } else {
                setDropdownActive(navLink.name);
              }
            }
          }}
          onKeyDown={event => {
            if (event.key === 'Enter') {
              setKeyboardNavActive(true);
            }
            if (event.key === 'Escape') {
              if (navLink.type === 'megaMenu') setMegaMenuActive(null);
              if (dropdownActive) {
                setDropdownActive(null);
              } else {
                setDropdownActive(navLink.name);
              }
            }
          }}
        >
          {navLink.type === 'megaMenu' && (
            <NavDesktopButton ref={shopNavItem}>
              {flags.clickableShopMenuItem ? (
                <a href={navLink.link?.replace('/collections', '')}>
                  {navLink.name}
                </a>
              ) : (
                navLink.name
              )}
              <theme.navigation.DownArrow
                width={theme.navigation.downArrowSize}
                height={theme.navigation.downArrowSize}
              />
            </NavDesktopButton>
          )}
          {navLink.type === 'dropdown' && (
            <NavDesktopButton
              onFocus={() => {
                setMegaMenuActive(null);
              }}
            >
              {navLink.name}
              <theme.navigation.DownArrow
                width={theme.navigation.downArrowSize}
                height={theme.navigation.downArrowSize}
              />
            </NavDesktopButton>
          )}

          {navLink.type === 'dropdown' && (
            <DropdownMenu dropdownActive={dropdownActive === navLink.name}>
              <ul>
                {navLink.subSections.map(menuItem => (
                  <li key={menuItem.name}>
                    {menuItem.type === 'external' && (
                      <a
                        href={menuItem.link}
                        style={
                          menuItem.subSections && {
                            display: 'flex',
                            justifyContent: 'space-between',
                          }
                        }
                        onFocus={() => {
                          if (menuItem.subSections) setSmallMenuActive(true);
                        }}
                        onBlur={() => {}}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            setSmallMenuActive(!smallMenuActive);
                          }
                        }}
                      >
                        {menuItem.name}
                        {menuItem.subSections && (
                          <ChevronRight
                            height={22}
                            width={22}
                            style={{ marginTop: '.25rem' }}
                          />
                        )}
                      </a>
                    )}
                    {menuItem.type !== 'external' && (
                      <Link
                        to={menuItem.link?.replace('/pages', '')}
                        style={
                          menuItem.subSections && {
                            display: 'flex',
                            justifyContent: 'space-between',
                          }
                        }
                        onFocus={() => {
                          if (menuItem.subSections) setSmallMenuActive(true);
                        }}
                        onBlur={() => {}}
                        onKeyDown={event => {
                          if (event.key === 'Enter') {
                            setSmallMenuActive(!smallMenuActive);
                          }
                        }}
                      >
                        {menuItem.name}
                        {menuItem.subSections && (
                          <ChevronRight
                            height={22}
                            width={22}
                            style={{ marginTop: '.25rem' }}
                          />
                        )}
                      </Link>
                    )}
                    {menuItem.subSections && (
                      <DropdownSubMenu smallMenuActive={smallMenuActive}>
                        {menuItem.subSections.map(subSection => (
                          <li key={subSection.name}>
                            {subSection?.link?.startsWith('http') ? (
                              <a href={subSection.link}>{subSection.name}</a>
                            ) : (
                              <Link to={subSection.link?.replace('/pages', '')}>
                                {subSection.name}
                              </Link>
                            )}
                          </li>
                        ))}
                      </DropdownSubMenu>
                    )}
                  </li>
                ))}
              </ul>
            </DropdownMenu>
          )}
          {navLink.type === 'static' && (
            <NavDesktopLink to={navLink.link}>{navLink.name}</NavDesktopLink>
          )}
        </NavDesktopMenuItem>
      ))}
    </Wrapper>
  );
}

NavDesktop.propTypes = {
  navLinks: PropTypes.arrayOf(PropTypes.shape({})),
  setMegaMenuActive: PropTypes.func,
  setKeyboardNavActive: PropTypes.func,
  megaMenuActive: PropTypes.number,
  keyboardNavActive: PropTypes.bool,
  flags: PropTypes.shape({
    clickableShopMenuItem: PropTypes.bool,
  }),
};

NavDesktop.defaultProps = {
  navLinks: [],
  setMegaMenuActive: () => {},
  megaMenuActive: null,
  setKeyboardNavActive: () => {},
  keyboardNavActive: false,
  flags: { clickableShopMenuItem: false },
};

export default NavDesktop;
