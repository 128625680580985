import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';
import breakpoints from '@rsc/ui-components/breakpoints';
import sharedStyles from '@rsc/ui-components/styles';
import './PetsafeTypeKit.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

export const GlobalStyles = createGlobalStyle`
  ${normalize};

  html, body {
    font-size: ${sharedStyles.fontSize.base};
    font-family: "proxima-nova", sans-serif;
    font-weight: ${sharedStyles.fontWeight.regular};
    line-height: 1.5;
    box-sizing: border-box;
    color: ${({ theme }) => theme.colors.defaultFont};
    text-rendering: optimizeLegibility;
    background: ${({ theme }) => theme.colors.background};
  }

  *, *::before, *::after {
    box-sizing: inherit;
    line-height: inherit;
  }

  /* this is needed to make styled inputs work on Safari 🤷
  https://stackoverflow.com/questions/49889003/cannot-write-into-input-field-on-safari */
  input, input:before, input:after {
    -webkit-user-select: initial;
    -khtml-user-select: initial;
    -moz-user-select: initial;
    -ms-user-select: initial;
    user-select: initial;
  }

  a {
    color: ${({ theme }) => theme.colors.link};

    &:hover {
      text-decoration: none;
    }
  }

  p {
    margin: 0 0 1em;
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1.15;
    margin: 0 0 0.75em;
  }
  
  h1 {
    font-size: 2.25em;
  }
  
  h2 {
    font-size: 1.75em;
  }
  
  h3{
    font-size: 1.25em;
  }

  strong {
    font-style: inherit;
    font-weight: ${sharedStyles.fontWeight.bold};
  }

  sup {
    top: revert;
    vertical-align: super;
    font-size: 75%;
  }

  ol {
    list-style-type: decimal;
    margin-left: ${sharedStyles.spacing['spacing-md']};

    @media screen and (min-width: ${breakpoints.md}){
      margin-left: 0;
    }
  }

  ol li {
    margin-bottom: ${sharedStyles.spacing['spacing-md']};
  }

  input, select, textarea {
    background-color: white;
    border: 1px solid ${({ theme }) => theme.colors.border};
    font-family: inherit;
  }

`;

export default GlobalStyles;
