import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import algoliasearch from 'algoliasearch/lite';
import {
  Configure,
  Index,
  connectStateResults,
  connectSearchBox,
  InstantSearch,
  Hits,
  connectHitInsights,
} from 'react-instantsearch-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { aa } from '@rsc/utils/algoliaAnalytics';
import styles from '@rsc/ui-components/styles';
import LinkComponent from '@rsc/ui-components/LinkComponent';
import SearchForm from './search-form/SearchForm';
import SearchFormHit from './search-form/SearchFormHit';

const CustomSearchFormHit = connectHitInsights(aa)(SearchFormHit);

const HitsContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 54px;
  background: white;
  color: ${({ theme }) => theme.colors.navSearchHitsContainerText};
  h2 {
    padding: ${styles.spacing['spacing-sm']} ${styles.spacing['spacing-md']} 0;
    margin: 0;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  li {
    &:last-child > div {
      border-bottom: none;
    }
  }
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);
`;

const UnavailableMessage = styled.div`
  background: inherit;
  position: absolute;
  width: 100%;
  color: white;
  padding: 0.75rem 1rem;
  font-weight: normal;
  font-size: 0.875rem;
`;

function HitsArea({ refine, children }) {
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        refine('');
      }}
    >
      <HitsContainer
        onBlur={() => {
          refine('');
        }}
      >
        {children}
      </HitsContainer>
    </OutsideClickHandler>
  );
}

const AllResultsLink = styled(LinkComponent)`
  background: revert;
  display: block;
  color: ${({ theme }) => theme.colors.navSearchAllResultsColor};
  padding: ${styles.spacing['spacing-sm']} ${styles.spacing['spacing-md']};
  font-weight: normal;
  font-size: ${styles.fontSize.base};
`;

const CustomSearchBox = connectSearchBox(SearchForm);

const ConnectedHitsArea = connectSearchBox(HitsArea);

let firstLoad = true;

const algoliaClient = algoliasearch(
  process.env.GATSBY_ALGOLIA_APP_ID,
  process.env.GATSBY_ALGOLIA_SEARCH_KEY
);

function NavSearch({ searchIndexes }) {
  const [activeSearchState, setActiveSearchState] = useState({});
  const [apiError, setApiError] = useState(false);

  let searchRes;

  const Results = connectStateResults(
    ({ searchState, searchResults, children }) => {
      if (searchState && searchResults?.hits?.length && searchState.query) {
        searchRes = searchResults;
        return (
          <>
            {children}
            <AllResultsLink
              to={`/search?query=${searchState.query}`}
              state={{ searchResults }}
            >
              Show all results for &quot;{searchState.query}&quot;
            </AllResultsLink>
          </>
        );
      }
      return null;
    }
  );

  const searchClient = {
    search(requests) {
      // prevent request on first load
      if (firstLoad === true) {
        firstLoad = false;
        return;
      }

      // prevent request if no params
      if (requests.every(({ params }) => !params.query)) {
        return;
      }
      // eslint-disable-next-line consistent-return
      return algoliaClient.search(requests).catch(() => setApiError(true));
    },
  };

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName="petsafe-support-pages"
      onSearchStateChange={newSearchState =>
        setActiveSearchState(newSearchState)
      }
    >
      <Configure hitsPerPage={5} clickAnalytics />
      <CustomSearchBox
        brandName="Brand Name"
        onSubmit={evt => {
          evt.preventDefault();
          navigate(`/search?query=${activeSearchState.query}`, {
            state: {
              searchResults: searchRes,
            },
          });
        }}
      />
      {apiError ? (
        <OutsideClickHandler
          onOutsideClick={() => {
            setApiError(false);
          }}
        >
          <UnavailableMessage>
            Search is currently unavailable
          </UnavailableMessage>
        </OutsideClickHandler>
      ) : (
        <ConnectedHitsArea>
          {searchIndexes?.includes('petsafe-support-articles') && (
            <Index indexName="petsafe-support-articles">
              <Results indexName="petsafe-support-articles">
                <h2>Articles</h2>
                <Hits hitComponent={CustomSearchFormHit} />
              </Results>
            </Index>
          )}
          {searchIndexes?.includes('petsafe-support-pages') && (
            <Index indexName="petsafe-support-pages">
              <Results indexName="petsafe-support-pages">
                <h2>Products</h2>
                <Hits hitComponent={CustomSearchFormHit} />
              </Results>
            </Index>
          )}
        </ConnectedHitsArea>
      )}
    </InstantSearch>
  );
}

NavSearch.propTypes = {
  searchIndexes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

HitsArea.propTypes = {
  refine: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default NavSearch;
