const styles = {
  colors: {
    gray: {
      50: '#F5F5F5',
      100: '#EBEBEB',
      200: '#D4D4D4',
      300: '#B8B8B8',
      400: '#969696',
      500: '#666666',
      600: '#595959',
      700: '#4F4F4F',
      800: '#404040',
      900: '#262626',
    },
    orange: {
      500: '#F79727',
    },
    white: '#ffffff',
    black: '#000000',
    red: {
      100: '#ffdddd',
      300: '#ff5d5d',
      500: '#d91a00',
    },
    green: {
      500: '#8CC63E',
    },
    blue: {
      50: '#EBF7FF',
      100: '#0160a6',
      200: '#A3D9FF',
      300: '#6BC1FF',
      400: '#0A99FF',
      500: '#0067B1',
      600: '#005FA3',
      700: '#005694',
      800: '#00467d',
      900: '#003C66',
      1000: '#3672D1',
      1050: '#083874',
    },
  },
};

export default styles;
