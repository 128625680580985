import React from 'react';
import styled from 'styled-components';
import breakpoints from '@rsc/ui-components/breakpoints';
import styles from '@components/utils/styles';
import { StyledLink } from './Footer';

const Container = styled.ul`
  margin: 0;
  padding: 0;

  li {
    font-size: 0.875rem;
    color: ${styles.colors.white};
    padding: 0.5rem 0;
    text-transform: uppercase;
    text-align: center;
    list-style: none;

    @media (min-width: ${breakpoints.md}) {
      text-align: left;
    }

    a {
      color: ${styles.colors.white};
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;

function StoreList() {
  return (
    <Container>
      <StyledLink
        href="https://store.petsafe.net/fencing/wireless-fences"
        target="_blank"
        rel="noopener noreferrer"
      >
        Wireless Fences
      </StyledLink>
      <StyledLink
        href="https://store.petsafe.net/fencing/in-ground-fences"
        target="_blank"
        rel="noopener noreferrer"
      >
        In-Ground Fences
      </StyledLink>
      <StyledLink
        href="https://store.petsafe.net/litter-boxes"
        target="_blank"
        rel="noopener noreferrer"
      >
        Litter Boxes
      </StyledLink>
      <StyledLink
        href="https://store.petsafe.net/bark-control/collars"
        target="_blank"
        rel="noopener noreferrer"
      >
        Bark Collars
      </StyledLink>
      <StyledLink
        href="https://store.petsafe.net/accessories/batteries"
        target="_blank"
        rel="noopener noreferrer"
      >
        Batteries
      </StyledLink>
    </Container>
  );
}

export default StoreList;
