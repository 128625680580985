import styled from 'styled-components';
import PropTypes from 'prop-types';
import breakpoints from '../breakpoints';

const PageWrapper = styled.div`
  background-color: ${({ background, theme }) =>
    background || theme.colors.background};
  max-width: ${({ fullWidth }) => (fullWidth ? '100%' : breakpoints.xl)};
  margin: 0 auto;
  padding: ${({ padding }) => padding};
  @media screen and (min-width: ${breakpoints.md}) {
    padding: ${({ padding }) => padding};
  }
`;

PageWrapper.propTypes = {
  background: PropTypes.string,
  padding: PropTypes.string,
  fullWidth: PropTypes.bool,
};

PageWrapper.defaultProps = {
  fullWidth: false,
  padding: '1.625rem',
};

export default PageWrapper;
