/* eslint-disable import/no-cycle */
import React, { useState } from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Hamburger from 'hamburger-react';
import styles from '@components/utils/styles';
import breakpoints from '@rsc/ui-components/breakpoints';
import NavDesktop from './NavDesktop';
import MegaMenu from './MegaMenu';
import NavMobile from './NavMobile';
import NavSearch from './NavSearch';

const Wrapper = styled.header`
  background: ${styles.colors.blue[1050]};
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
`;

const Inner = styled.div`
  background: inherit;
  display: flex;
  align-items: center;
  padding: 0;
  max-width: ${breakpoints.xl};
  width: 100%;
  z-index: 2;
  height: 74px;
`;

const MobileLogo = styled(Link)`
  display: flex;
  flex: 1;
  ${({ theme }) =>
    theme.images.mobileLogo
      ? `background-image: url(${theme.images.mobileLogo});`
      : `background-image: url(${theme.images.logo});`}
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin-right: 1rem;
  height: ${({ theme }) => theme.logoHeight};
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const DesktopLogo = styled(Link)`
  display: none;
  background-image: url(${({ theme }) => theme.images.logo});
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;
  height: 108px;
  @media (min-width: ${breakpoints.lg}) {
    display: flex;
    width: 315px;
  }
  @media (min-width: ${breakpoints.xl}) {
  }
`;

const MobileNavTrigger = styled.a`
  width: 48px;
  height: 48px;
  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }

  .hamburger-react div {
    background: ${({ theme }) => theme.colors.defaultFont}!important;
  }
`;

const SearchContainer = styled.div`
  display: none;
  position: relative;
  width: 20rem;
  @media (min-width: ${breakpoints.lg}) {
    display: block;
  }

  @media (min-width: ${breakpoints.xl}) {
    width: 25rem;
  }
`;

function Header({ menuStructure, searchIndexes, flags }) {
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [megaMenuActive, setMegaMenuActive] = useState(null);
  const [keyboardNavActive, setKeyboardNavActive] = useState(false);

  // Only use menu items that have a 'type' key
  // otherwise ignore. See shopifyMenuSettings.json
  // for reference
  const navLinks = menuStructure?.menuSections?.filter(section => section.type);

  return (
    <Wrapper>
      <Inner>
        <MobileNavTrigger href="#">
          <Hamburger
            size={24}
            toggled={mobileMenuActive}
            toggle={setMobileMenuActive}
            label="Mobile Navigation"
          />
        </MobileNavTrigger>
        <MobileLogo to="/" />
        <DesktopLogo to="/" />
        <NavDesktop
          navLinks={navLinks}
          setMegaMenuActive={setMegaMenuActive}
          megaMenuActive={megaMenuActive}
          setKeyboardNavActive={setKeyboardNavActive}
          keyboardNavActive={keyboardNavActive}
          flags={flags}
        />
        <SearchContainer>
          <NavSearch searchIndexes={searchIndexes} />
        </SearchContainer>
      </Inner>

      <NavMobile
        mobileMenuActive={mobileMenuActive}
        navLinks={navLinks}
        flags={flags}
        searchIndexes={searchIndexes}
      />

      {navLinks.map(
        (shopSection, id) =>
          shopSection.type === 'megaMenu' &&
          shopSection.subSections?.length && (
            <MegaMenu
              key={`mega-${shopSection?.name}}`}
              megaMenuIndex={id}
              megaMenuActive={megaMenuActive}
              setMegaMenuActive={setMegaMenuActive}
              keyboardNavActive={keyboardNavActive}
              menuStructure={shopSection}
              columns={shopSection.columns}
            />
          )
      )}
    </Wrapper>
  );
}

Header.propTypes = {
  menuStructure: PropTypes.shape({
    menuSections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        type: PropTypes.string,
        subSections: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
            subSections: PropTypes.arrayOf(
              PropTypes.shape({
                name: PropTypes.string,
                link: PropTypes.string,
              })
            ),
          })
        ),
      })
    ),
  }).isRequired,
  searchIndexes: PropTypes.arrayOf(PropTypes.string),
  flags: PropTypes.shape({}),
};

Header.defaultProps = {
  searchIndexes: [],
  flags: {},
};

export default Header;
