/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';

function LinkComponent({ children = null, ...props }) {
  const isInternalLink = /^\/(?!\/)/.test(props?.to);

  if (!isInternalLink) {
    return (
      <a href={props?.to || props?.href} {...props}>
        {children}
      </a>
    );
  }

  if (props?.to) {
    return <Link {...props}>{children}</Link>;
  }

  return null;
}

LinkComponent.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  children: PropTypes.node,
};

export default LinkComponent;
