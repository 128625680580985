import styles from './utils/styles';

const colors = {
  accordion: {
    background: '#e8e8e8',
    backgroundHover: '#d1d1d1',
  },
  background: '#e8e8e8',
  border: styles.colors.blue[100],
  brandPrimary: styles.colors.blue[1050],
  brandSecondary: 'white',
  breadcrumbLinkColor: styles.colors.blue[1050],
  callout: '#C4E6FF',
  carouselBulletHover: styles.colors.gray[500],
  defaultFont: styles.colors.gray[500],
  headerBackground: styles.colors.blue[100],
  inputBorder: styles.colors.gray[300],
  inputFontColor: styles.colors.gray[500],
  link: styles.colors.blue[1050],
  linkHover: styles.colors.blue[1050],
  supportArticleLink: '#5d5d5d',
  searchBorder: styles.colors.gray[200],
  searchInput: styles.colors.gray[500],
  tagBackground: styles.colors.blue[100],
  cardBackground: 'white',
  cardBackgroundHover: styles.colors.gray[50],
  cardText: styles.colors.gray[500],
  navSearchAllResultsColor: styles.colors.gray[500],
  navSearchHitsContainerText: styles.colors.gray[800],
  searchFormHitBorderColor: styles.colors.gray[200],
  NavLinkColor: 'white',
};

export default colors;
