import React from 'react';
import styled from 'styled-components';
import breakpoints from '@rsc/ui-components/breakpoints';
import styles from '@components/utils/styles';
import { StyledLink } from './Footer';

const Container = styled.ul`
  margin: 0;
  padding: 0;

  li {
    font-size: 0.875rem;
    color: ${styles.colors.white};
    padding: 0.5rem 0;
    text-transform: uppercase;
    text-align: center;
    list-style: none;

    @media (min-width: ${breakpoints.md}) {
      text-align: left;
    }

    a {
      color: ${styles.colors.white};
      text-decoration: none;
    }

    a:hover {
      text-decoration: underline;
    }
  }
`;

const AboutUsList = () => (
  <Container>
    <StyledLink
      href="https://www.petsafe.net/about-us"
      target="_blank"
      rel="noopener noreferrer"
    >
      About PetSafe
    </StyledLink>
    <StyledLink
      href="https://www.petsafe.net/about-us/press-releases"
      target="_blank"
      rel="noopener noreferrer"
    >
      Press Releases
    </StyledLink>
    <StyledLink
      href="https://www.petsafe.net/about-us/working-at-petsafe"
      target="_blank"
      rel="noopener noreferrer"
    >
      Careers
    </StyledLink>
    <StyledLink
      href="https://www.petsafe.net/barkforyourpark"
      target="_blank"
      rel="noopener noreferrer"
    >
      Bark for Your Park
    </StyledLink>
    <StyledLink
      href="https://www.petsafe.net/about-us/community-outreach"
      target="_blank"
      rel="noopener noreferrer"
    >
      Community Outreach
    </StyledLink>
    <StyledLink
      href="https://www.petsafe.net/about-us/joinpanel"
      target="_blank"
      rel="noopener noreferrer"
    >
      Join Our Testing Panel
    </StyledLink>
    <StyledLink
      href="https://www.petsafe.net/about-us/petsafe-affiliate-program"
      target="_blank"
      rel="noopener noreferrer"
    >
      Affiliate Program
    </StyledLink>
  </Container>
);

export default AboutUsList;
