import React from 'react';
import { navigate } from 'gatsby';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import breakpoints from '@rsc/ui-components/breakpoints';
import IconSearch from '@rsc/ui-components/svgs/search-icon.svg';
import styles from '@rsc/ui-components/styles';

const SearchFormContainer = styled.form`
  position: relative;
  display: flex;
  flex: 1;
  width: 100%;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  @media (min-width: ${breakpoints.lg}) {
    margin-bottom: 0;
  }
`;

const SearchInputField = styled.input`
  overflow: hidden;
  flex: 1;
  padding: ${styles.spacing['spacing-md']} ${styles.spacing['spacing-sm']};
  opacity: 1;
  color: ${({ theme }) => theme.colors.searchBackground};
  background-color: white;
  border: 1px solid ${({ theme }) => theme.colors.searchBorder};
  font-weight: inherit;
  position: relative;
  padding-left: ${styles.spacing['spacing-1xl']};
  appearance: none;
  border-radius: 0;
  font-size: ${styles.fontSize.base};

  &::placeholder {
    opacity: 0.75;
    line-height: 1.6;
  }
  &:read-only {
    background-color: inherit;
    color: ${({ theme }) => theme.colors.searchInput};
  }

  svg {
    position: absolute;
    left: 0;
  }
`;

const Icon = styled(IconSearch)`
  position: absolute;
  left: 0.75rem;
  height: 1rem;
  top: 33%;
  opacity: 0.5;
`;

function SearchForm({ currentRefinement, refine, disabled, onSubmit }) {
  return (
    <SearchFormContainer
      onSubmit={onSubmit}
      noValidate
      role="search"
      action="."
      disabled={disabled}
    >
      <SearchInputField
        type="text"
        value={currentRefinement}
        onChange={event => refine(event.currentTarget.value)}
        placeholder="Search"
        disabled={disabled}
      />
      <Icon />
    </SearchFormContainer>
  );
}

SearchForm.propTypes = {
  currentRefinement: PropTypes.string,
  refine: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
};

SearchForm.defaultProps = {
  disabled: false,
  currentRefinement: null,
  refine: () => {},
  onSubmit: event => {
    event.preventDefault();
    navigate(`/search`);
  },
};

export default SearchForm;
