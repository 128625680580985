import { DefaultTheme } from '@rsc/ui-components/theme';
import PetSafeLogo from '@images/ps-support-logo.svg';
import styles from './utils/styles';
import themeColors from './themeColors';

// use shared template and extend/override with custom theme values

const theme = {
  ...DefaultTheme,
  button: {
    ...DefaultTheme.button,
    primary: {
      ...DefaultTheme.button.primary,
      background: styles.colors.blue[500],
      backgroundHover: styles.colors.blue[700],
      backgroundActive: styles.colors.blue[900],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[500],
    },
    secondary: {
      ...DefaultTheme.button.secondary,
      background: 'white',
      backgroundHover: styles.colors.gray[200],
      backgroundActive: styles.colors.gray[300],
      backgroundDisabled: 'white',
      borderColor: styles.colors.blue[500],
      textColor: styles.colors.blue[500],
    },
    tertiary: {
      ...DefaultTheme.button.tertiary,
      background: styles.colors.blue[500],
      backgroundHover: styles.colors.blue[700],
      backgroundActive: styles.colors.blue[900],
      backgroundDisabled: styles.colors.blue[600],
      borderColor: styles.colors.blue[500],
    },
  },
  colors: {
    ...DefaultTheme.colors,
    ...themeColors,
  },
  hero: {
    fontColor: 'white',
  },
  images: {
    logo: PetSafeLogo,
    mobileLogo: PetSafeLogo,
  },
  name: 'PetSafe Theme',
  notificationBanner: {
    background: styles.colors.blue[800],
  },
  productCard: {
    ...DefaultTheme.productCard,
    borderColorHover: styles.colors.gray[100],
    boxShadowHover: 'none',
    priceColor: styles.colors.blue[500],
    titleColor: styles.colors.blue[500],
    titleColorHover: styles.colors.blue[500],
    titleTextTransform: 'inherit',
  },
  fontWeight: {
    ...DefaultTheme.fontWeight,
  },
};

export default theme;
