import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Collapsible from 'react-collapsible';
import breakpoints from '@rsc/ui-components/breakpoints';
import ChevronUp from '@rsc/ui-components/svgs/chevron-up.svg';
import ChevronDown from '@rsc/ui-components/svgs/chevron-down.svg';
import NavSearch from './NavSearch';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  background: ${({ theme }) => theme.colors.background};
  z-index: 1;
  transition: all 0.25s ease;
  padding-top: 100px;
  top: ${props => (props.mobileMenuActive ? null : '-120rem')};

  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);

  @media (min-width: ${breakpoints.lg}) {
    display: none;
  }
`;

const NavMobileSubMenu = styled.div`
  section {
    padding-left: 1rem;
    margin-bottom: 0.75rem;
  }
  ul,
  li {
    padding-left: 0.5rem;
    margin: 0;
    list-style: none;
  }
  .second-level {
    margin-bottom: 0;
    padding-left: 0;
  }
  li {
    margin-bottom: 0.75rem;
    a {
      font-size: inherit;
      text-decoration: none;
      padding-left: 0.5rem;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;

      &:hover {
        text-decoration: underline;
      }
    }
  }
  ul {
    margin-bottom: 1.25rem;
  }

  .Collapsible__trigger {
    display: flex;
    align-items: center;

    > a {
      width: 85%;
    }

    svg {
      flex: 1;
      fill: ${({ theme }) => theme.colors.defaultFont};
      margin: unset;
    }
  }
`;

const SearchContainer = styled.div`
  padding: 0 1rem;
  position: relative;
  z-index: 1;
`;

const NavMobileLink = styled(Link)`
  cursor: pointer;
  color: inherit;
  font-weight: inherit;
  background-repeat: no-repeat;
  background-position: right 1.5rem top 0.85rem;
  background-size: 1rem;
  display: flex;
  font-size: 1.1875rem;
  font-weight: inherit;
  padding: 0.5rem 1rem;
  text-decoration: none;
  margin-bottom: 0.5rem;

  ${({ $noArrow }) => $noArrow && `background: none;`}

  > span {
    flex: 1;
  }
`;

const NavMobileSubLink = styled(Link)`
  cursor: pointer;
  color: inherit;
  font-weight: inherit;
  background-repeat: no-repeat;
  background-position: right 1.6rem top 0.25rem;
  background-size: 0.85rem;
  display: flex;
  font-size: 1rem;
  padding-right: 1rem;
  text-decoration: none;

  svg {
    fill: ${({ theme }) => theme.colors.defaultFont};
    margin-top: 0.25rem;
  }

  > span {
    flex: 1;
  }
`;

function NavMobile({ mobileMenuActive, navLinks, flags, searchIndexes }) {
  return (
    <Wrapper mobileMenuActive={mobileMenuActive}>
      <SearchContainer>
        <NavSearch searchIndexes={searchIndexes} />
      </SearchContainer>
      {navLinks.map(navLink => {
        if (navLink.subSections) {
          return (
            <NavMobileSubMenu key={navLink.name}>
              <Collapsible
                transitionTime={100}
                trigger={
                  <>
                    <NavMobileLink
                      key={navLink.name}
                      to={navLink.link?.replace('/collections', '') || '/'}
                      onClick={e =>
                        flags.clickableShopMenuItem && navLink.name === 'Shop'
                          ? ''
                          : e.preventDefault()
                      }
                    >
                      <span>{navLink.name}</span>
                    </NavMobileLink>
                    <ChevronDown width={20} height={20} />
                  </>
                }
                triggerWhenOpen={
                  <>
                    <NavMobileLink
                      key={navLink.name}
                      to={navLink.link?.replace('/collections', '') || '/'}
                      onClick={e =>
                        flags.clickableShopMenuItem && navLink.name === 'Shop'
                          ? ''
                          : e.preventDefault()
                      }
                      open
                    >
                      <span>{navLink.name}</span>
                    </NavMobileLink>
                    <ChevronUp width={20} height={20} />
                  </>
                }
              >
                {navLink.subSections?.map(section => {
                  if (section.subSections) {
                    return (
                      <Collapsible
                        key={section.name}
                        transitionTime={100}
                        trigger={
                          <>
                            <NavMobileSubLink
                              key={section.name}
                              to={
                                section.link
                                  ?.replace('/pages', '')
                                  .replace('/collections', '') || '/'
                              }
                              onClick={e => e.preventDefault()}
                              aria-label={section.name}
                            >
                              <span>{section.name}</span>
                            </NavMobileSubLink>
                            <ChevronDown width={20} height={20} />
                          </>
                        }
                        triggerWhenOpen={
                          <>
                            <NavMobileSubLink
                              key={section.name}
                              to={
                                section.link
                                  ?.replace('/pages', '')
                                  .replace('/collections', '') || '/'
                              }
                              onClick={e => e.preventDefault()}
                              open
                              aria-label={section.name}
                            >
                              <span>{section.name}</span>
                            </NavMobileSubLink>
                            <ChevronUp width={20} height={20} />
                          </>
                        }
                        triggerTagName="section"
                      >
                        <ul>
                          {section.subSections.map(
                            subSection =>
                              subSection.name !== 'Privacy Policy' &&
                              subSection.name !== 'Safe Again Program' && (
                                <li key={subSection.name}>
                                  {subSection?.link?.startsWith('http') ? (
                                    <a href={subSection.link}>
                                      {subSection.name}
                                    </a>
                                  ) : (
                                    <Link
                                      to={
                                        subSection.link
                                          ?.replace('/pages', '')
                                          .replace('/collections', '') || '/'
                                      }
                                    >
                                      {subSection.name}
                                    </Link>
                                  )}
                                </li>
                              )
                          )}
                          {section.link?.includes('/collections/') && (
                            <li key={section.name}>
                              <Link
                                to={
                                  section.link
                                    ?.replace('/pages', '')
                                    .replace('/collections', '') || '/'
                                }
                              >
                                Shop All {section.name}
                              </Link>
                            </li>
                          )}
                        </ul>
                      </Collapsible>
                    );
                  }

                  return (
                    <ul key={section.name} className="second-level">
                      <li>
                        {section.type === 'external' ? (
                          <a href={section.link}>{section.name}</a>
                        ) : (
                          <Link
                            to={
                              section.link
                                ?.replace('/pages', '')
                                .replace('/collections', '') || '/'
                            }
                          >
                            {section.name}
                          </Link>
                        )}
                      </li>
                    </ul>
                  );
                })}
              </Collapsible>
            </NavMobileSubMenu>
          );
        }

        return (
          <NavMobileLink to={navLink.link} key={navLink.name} $noArrow>
            <span>{navLink.name}</span>
          </NavMobileLink>
        );
      })}
    </Wrapper>
  );
}

NavMobile.propTypes = {
  accountLinkComponent: PropTypes.shape({}),
  mobileMenuActive: PropTypes.bool,
  navLinks: PropTypes.arrayOf(PropTypes.shape({})),
  flags: PropTypes.shape({
    clickableShopMenuItem: PropTypes.bool,
  }),
  searchIndexes: PropTypes.arrayOf(PropTypes.string),
};

NavMobile.defaultProps = {
  accountLinkComponent: {},
  mobileMenuActive: false,
  navLinks: [],
  flags: { clickableShopMenuItem: false },
  searchIndexes: [],
};

export default NavMobile;
