import React from 'react';
import { Link } from 'gatsby';
import { styles } from '@components/utils';
import breakpoints from '@rsc/ui-components/breakpoints';
import styled, { css } from 'styled-components';
import IconFacebook from '@components/svgs/facebook-icon.svg';
import IconTwitter from '@components/svgs/twitter-icon.svg';
import IconInstagram from '@components/svgs/instagram-icon.svg';
import IconPinterest from '@components/svgs/pinterest-icon.svg';
import IconYoutube from '@components/svgs/youtube-icon.svg';

const SocialIconsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  text-align: center;
  margin: 30px 0;
  align-items: center;
  a {
    width: 33%;
    margin-bottom: 20px;
  }
  @media (min-width: ${breakpoints.md}) {
    justify-content: flex-start;
    margin: 0;
    a {
      width: 30%;
      margin: 10px 2px;
    }
  }
`;

const SocialIconStyles = css`
  height: 58px;
  width: 58px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  cursor: pointer;
  @media (min-width: ${breakpoints.md}) {
    height: 36px;
    width: 36px;
  }
  @media (min-width: ${breakpoints.lg}) {
    height: 58px;
    width: 58px;
  }
  ${{
    fill: styles.colors.blue[1000],
  }}
  &:hover {
    ${{
      fill: styles.colors.white,
    }}
  }
  &:first-child {
    margin-left: 0;
  }
`;

const FacebookIcon = styled(IconFacebook)`
  ${SocialIconStyles}
`;

const TwitterIcon = styled(IconTwitter)`
  ${SocialIconStyles}
`;

const InstagramIcon = styled(IconInstagram)`
  ${SocialIconStyles}
`;

const PinterestIcon = styled(IconPinterest)`
  ${SocialIconStyles}
`;

const YoutubeIcon = styled(IconYoutube)`
  ${SocialIconStyles}
`;

const BlogLink = styled(Link)`
  color: ${styles.colors.white};
  text-decoration: none;
  font-size: 1.625rem;
  font-weight: 400;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 15px;
    width: 100%;
    text-align: center;
    flex-basis: 100%;
    text-align: left;
  }
  &:hover {
    text-decoration: underline;
  }
`;

function SocialIcons() {
  return (
    <SocialIconsContainer>
      <a
        href="https://www.facebook.com/petsafebrand"
        rel="noopener noreferrer"
        target="_blank"
      >
        <FacebookIcon />
      </a>
      <a
        href="https://www.twitter.com/petsafe"
        rel="noopener noreferrer"
        target="_blank"
      >
        <TwitterIcon />
      </a>
      <a
        href="https://www.instagram.com/petsafe/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <InstagramIcon />
      </a>
      <a
        href="https://www.pinterest.com/petsafe/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <PinterestIcon />
      </a>
      <a
        href="https://www.youtube.com/user/petsafebrand/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <YoutubeIcon />
      </a>
      <BlogLink to="https://www.petsafe.net/learn">
        Good Reads by PetSafe
      </BlogLink>
    </SocialIconsContainer>
  );
}

export default SocialIcons;
