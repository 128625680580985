exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contact-us-claims-jsx": () => import("./../../../src/pages/contact-us/claims.jsx" /* webpackChunkName: "component---src-pages-contact-us-claims-jsx" */),
  "component---src-pages-contact-us-index-jsx": () => import("./../../../src/pages/contact-us/index.jsx" /* webpackChunkName: "component---src-pages-contact-us-index-jsx" */),
  "component---src-pages-contact-us-success-jsx": () => import("./../../../src/pages/contact-us/success.jsx" /* webpackChunkName: "component---src-pages-contact-us-success-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-simply-clean-warranty-complete-jsx": () => import("./../../../src/pages/simply-clean-warranty/complete.jsx" /* webpackChunkName: "component---src-pages-simply-clean-warranty-complete-jsx" */),
  "component---src-pages-simply-clean-warranty-index-jsx": () => import("./../../../src/pages/simply-clean-warranty/index.jsx" /* webpackChunkName: "component---src-pages-simply-clean-warranty-index-jsx" */),
  "component---src-templates-product-support-page-jsx": () => import("./../../../src/templates/product-support-page.jsx" /* webpackChunkName: "component---src-templates-product-support-page-jsx" */),
  "component---src-templates-support-article-jsx": () => import("./../../../src/templates/support-article.jsx" /* webpackChunkName: "component---src-templates-support-article-jsx" */),
  "component---src-templates-support-category-jsx": () => import("./../../../src/templates/support-category.jsx" /* webpackChunkName: "component---src-templates-support-category-jsx" */)
}

