import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import breakpoints from '@rsc/ui-components/breakpoints';

const Wrapper = styled.menu`
  box-sizing: border-box;
  border-top: solid 1px inherit;
  border-bottom: solid 1px inherit;
  width: 100%;
  display: ${props =>
    props.megaMenuActive === props.megaMenuIndex ? 'flex' : 'none'};
  justify-content: center;
  background: ${({ theme }) => theme.colors.background};
  position: absolute;
  padding: 2rem;
  left: 0;
  top: 58px;
  z-index: 1;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.02),
    0 6.7px 5.3px rgba(0, 0, 0, 0.028), 0 12.5px 10px rgba(0, 0, 0, 0.035),
    0 22.3px 17.9px rgba(0, 0, 0, 0.042), 0 41.8px 33.4px rgba(0, 0, 0, 0.05),
    0 100px 80px rgba(0, 0, 0, 0.07);

  @media (max-width: calc(${breakpoints.lg} - 1)) {
    display: none;
  }
`;

const MegaMenuInner = styled.ul`
  list-style: none;
  margin: 0 auto;
  padding: 0 1rem;
  box-sizing: border-box;
  width: 100%;
  @media (min-width: ${breakpoints.xl}) {
    width: ${props =>
      props.columns < 5
        ? parseInt((parseInt(breakpoints.xl, 10) / 5) * props.columns, 10)
        : parseInt(breakpoints.xl, 10)}px;
  }
  display: ${props => (props.singleLevelLayout ? 'inline-block' : 'grid')};
  grid-template-columns: repeat(${props => props.columns}, 1fr);
  grid-gap: 1rem;
  ${props => props.singleLevelLayout && `column-count: ${props.columns};`}
  color: inherit;

  li {
    margin-bottom: 0.35rem;

    a {
      text-decoration: none;
      color: inherit;
      font-weight: normal;
      &:hover {
        text-decoration: underline;
      }
    }

    h2 {
      font-size: 1.25rem;
      margin-bottom: 0.75rem;
      text-transform: ${({ theme }) =>
        theme.navigation.subSectionHeadingTextTransform};
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        a {
          color: inherit;
          font-weight: inherit;
          text-decoration: none;
          font-size: 1rem;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
`;

const MenuFooter = styled.div`
  margin-top: 2.625rem;
`;

function MegaMenu({
  megaMenuIndex,
  megaMenuActive,
  setMegaMenuActive,
  keyboardNavActive,
  menuStructure,
  columns,
}) {
  const additionalLinks = [];

  let hasThirdLevel = false;
  menuStructure.subSections?.forEach(section => {
    if (!hasThirdLevel && section.subSections) hasThirdLevel = true;
  });

  if (hasThirdLevel) {
    menuStructure.subSections?.forEach(section => {
      if (!section.subSections) {
        additionalLinks.push(section);
      }
    });
  }

  const firstMenuItems = React.useRef([]);

  useEffect(() => {
    if (megaMenuActive > -1 && keyboardNavActive) {
      const uniqueArray = firstMenuItems.current
        .filter((item, pos) => firstMenuItems.current.indexOf(item) === pos)
        .filter(e => e);

      uniqueArray[0].getElementsByTagName('a')[0].focus();
    }
  }, [megaMenuActive]);

  return (
    <Wrapper
      megaMenuIndex={megaMenuIndex}
      megaMenuActive={megaMenuActive}
      onMouseEnter={() => {
        setMegaMenuActive(megaMenuIndex);
      }}
      onMouseLeave={() => {
        setMegaMenuActive(null);
      }}
      onKeyDown={event => {
        if (keyboardNavActive && event.key === 'Escape') {
          setMegaMenuActive(null);
        }
      }}
    >
      <MegaMenuInner columns={columns} singleLevelLayout={!hasThirdLevel}>
        {menuStructure.subSections?.map(
          (section, index) =>
            index <
              menuStructure.subSections.length - additionalLinks.length && (
              <li
                key={`${section.name}-wrap`}
                ref={el => {
                  if (index === 0) {
                    firstMenuItems.current.push(el);
                    return el;
                  }

                  return null;
                }}
              >
                {section.name && section.link && (
                  <>
                    {!section.subSections &&
                      !hasThirdLevel &&
                      (section?.link?.startsWith('http') ? (
                        <a href={section.link}>{section.name}</a>
                      ) : (
                        <Link to={section.link?.replace('/collections', '')}>
                          {section.name}
                        </Link>
                      ))}
                    {section.subSections && (
                      <h2>
                        {section?.link?.startsWith('http') ? (
                          <a href={section.link}>{section.name}</a>
                        ) : (
                          <Link to={section.link?.replace('/collections', '')}>
                            {section.name}
                          </Link>
                        )}
                      </h2>
                    )}
                  </>
                )}
                {section.name &&
                  !section.link &&
                  (!hasThirdLevel || section.subSections) && (
                    <h2>{section.name}</h2>
                  )}
                {section.subSections && (
                  <ul>
                    {section.subSections?.map(subSection => (
                      <li key={`${subSection.name}-mega`}>
                        {subSection?.link?.startsWith('http') ? (
                          <a href={subSection.link}>{subSection.name}</a>
                        ) : (
                          <Link
                            to={subSection.link?.replace('/collections', '')}
                          >
                            {subSection.name}
                          </Link>
                        )}
                      </li>
                    ))}
                    {index ===
                      menuStructure.subSections.length -
                        (additionalLinks.length + 1) && (
                      <MenuFooter>
                        {additionalLinks.map(additionalSection => (
                          <h2 key={`${additionalSection.name}-heading`}>
                            {additionalSection?.link?.startsWith('http') ? (
                              <a href={additionalSection.link}>
                                {additionalSection.name}
                              </a>
                            ) : (
                              <Link
                                to={additionalSection.link?.replace(
                                  '/collections',
                                  ''
                                )}
                              >
                                {additionalSection.name}
                              </Link>
                            )}
                          </h2>
                        ))}
                      </MenuFooter>
                    )}
                  </ul>
                )}
              </li>
            )
        )}
      </MegaMenuInner>
    </Wrapper>
  );
}

MegaMenu.propTypes = {
  megaMenuIndex: PropTypes.number.isRequired,
  megaMenuActive: PropTypes.number,
  keyboardNavActive: PropTypes.bool,
  setMegaMenuActive: PropTypes.func,
  menuStructure: PropTypes.shape({
    name: PropTypes.string,
    link: PropTypes.string,
    type: PropTypes.string,
    subSections: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        link: PropTypes.string,
        subSections: PropTypes.arrayOf(
          PropTypes.shape({
            name: PropTypes.string,
            link: PropTypes.string,
          })
        ),
      })
    ),
  }).isRequired,
  columns: PropTypes.number,
};

MegaMenu.defaultProps = {
  megaMenuActive: null,
  keyboardNavActive: false,
  setMegaMenuActive: () => {},
  columns: 5,
};

export default MegaMenu;
