import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';
import styles from '@components/utils/styles';
import PageWrapper from '@rsc/ui-components/page-wrapper';
import breakpoints from '@rsc/ui-components/breakpoints';
import FooterColumn from './FooterColumn';
import StoreList from './StoreList';
import SocialIcons from './SocialIcons';
import AboutUsList from './AboutUsList';
import LegalLinks from './LegalLinks';

const Container = styled.footer`
  background-color: ${styles.colors.blue[1050]};
  margin-top: auto;
`;

const Inner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  @media (min-width: ${breakpoints.md}) {
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  font-size: 100%;
  line-height: 200%;
  color: ${styles.colors.white};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
    color: ${styles.colors.white};
  }
  > svg {
    margin-right: 0.5rem;
  }
`;

function Footer({ useQueryStringForEmailSignup }) {
  return (
    <Container>
      <PageWrapper background="inherit">
        <Inner>
          <FooterColumn heading="Customer Care">
            <StyledLink
              href="https://www.petsafe.net/support/policies-and-terms"
              target="_blank"
              rel="noopener noreferrer"
            >
              Returns & Policies
            </StyledLink>
            <StyledLink to="/contact-us">Contact Us</StyledLink>
            <StyledLink
              href="https://www.petsafe.net/where-to-buy"
              target="_blank"
              rel="noopener noreferrer"
            >
              Find A Retailer
            </StyledLink>
          </FooterColumn>
          <FooterColumn heading="Store">
            <StoreList />
          </FooterColumn>
          <FooterColumn heading="About Us">
            <AboutUsList />
          </FooterColumn>
          <FooterColumn heading="Connect With Us">
            <SocialIcons />
          </FooterColumn>
          <LegalLinks />
        </Inner>
      </PageWrapper>
    </Container>
  );
}

Footer.propTypes = {
  useQueryStringForEmailSignup: PropTypes.bool,
};

Footer.defaultProps = {
  useQueryStringForEmailSignup: false,
};

export default Footer;
