import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { TrackClickAfterSearch } from '@rsc/utils/algoliaAnalytics';
import LinkComponent from '@rsc/ui-components/LinkComponent';
import styles from '@rsc/ui-components/styles';

const Container = styled.div`
  border-bottom: solid 1px
    ${({ theme }) => theme.colors.searchFormHitBorderColor};

  &::first-child {
    border-top: none;
  }

  a {
    color: ${({ theme }) => theme.colors.searchInput};
    display: flex;
    padding: ${styles.spacing['spacing-sm']} ${styles.spacing['spacing-md']};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  p {
    font-size: ${styles.fontSize.sm};
    margin: 0;
    font-weight: normal;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  h3,
  h4 {
    font-size: inherit;
    font-weight: normal;
    margin: 0;
  }

  h3 {
    margin: 0 0 ${styles.spacing['spacing-2xs']};
    color: inherit;
    font-size: ${styles.fontSize.base};
  }

  h4 {
    font-size: ${styles.fontSize.sm};
  }
`;

const ImageContainer = styled.div`
  width: ${styles.spacing['spacing-2xl']};
  height: ${styles.spacing['spacing-2xl']};
  margin-right: ${styles.spacing['spacing-sm']};
  flex-shrink: 0;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

function SearchFormHit({ hit, insights }) {
  const { title, image, link } = hit;

  return (
    <Container>
      <LinkComponent
        to={link}
        onClick={() => {
          TrackClickAfterSearch(insights);
        }}
        // eslint-disable-next-line no-underscore-dangle
        state={{ searchedProduct: hit?.__queryID || null }}
      >
        {image && (
          <ImageContainer>
            <Image src={image} alt={title} />
          </ImageContainer>
        )}
        <div>
          <h3>{title}</h3>
        </div>
      </LinkComponent>
    </Container>
  );
}

SearchFormHit.propTypes = {
  hit: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string,
    link: PropTypes.string,
    __queryID: PropTypes.string,
  }),
  insights: PropTypes.string,
};

SearchFormHit.defaultProps = {
  hit: {
    excerpt: '',
    variants: [],
    slug: '',
    link: '',
  },
};

export default SearchFormHit;
