import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import styles from '@components/utils/styles';
import breakpoints from '@rsc/ui-components/breakpoints';

function FooterColumn({ heading, children }) {
  return (
    <Column>
      <Heading>{heading}</Heading>
      {children}
    </Column>
  );
}

const Column = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;

  @media (min-width: ${breakpoints.md}) {
    width: 50%;
    align-items: flex-start;
  }

  @media (min-width: ${breakpoints.lg}) {
    width: 25%;
  }
`;

const Heading = styled.h4`
  color: ${styles.colors.white};
  font-weight: 400;
  font-family: proxima-nova, sans-serif;
  font-size: 1.5rem;
  line-height: 31px;
  margin-bottom: 1rem;
`;

FooterColumn.propTypes = {
  heading: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default FooterColumn;
