import React from 'react';
import styled from 'styled-components';
import breakpoints from '@rsc/ui-components/breakpoints';

const LegalWrapper = styled.div`
  padding-left: 0;

  @media (min-width: ${breakpoints.md}) {
    padding-left: 1rem;
  }
`;

const Container = styled.p`
  font-size: 0.75rem;
  color: #fff;
  margin: 0;
  padding: 0;
  display: inline-block;
  vertical-align: bottom;
`;
const Links = styled.a`
  color: #fff;
`;

const CurrentYear = new Date().getFullYear();

function LegalLinks() {
  return (
    <LegalWrapper>
      <Container>
        ©{CurrentYear}{' '}
        <Links
          href="https://www.radiosystemscorporation.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Radio Systems Corporation
        </Links>{' '}
        All Rights Reserved.{' '}
        <Links
          href="https://www.petsafe.net/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy
        </Links>{' '}
        |{' '}
        <Links
          href="https://www.petsafe.net/support/wholesaler-info"
          target="_blank"
          rel="noopener noreferrer"
        >
          Wholesalers
        </Links>{' '}
        |{' '}
        <Links
          href="https://ascend.pepperjam.com/affiliate/registration.php?refid=141049"
          target="_blank"
          rel="noopener noreferrer"
        >
          Affiliates
        </Links>
      </Container>
    </LegalWrapper>
  );
}
export default LegalLinks;
